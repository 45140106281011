import {createEvent, createStore} from '@cobuildlab/react-simple-state';
import {AuthStep, AuthStore} from './auth-types';
import {universalCookies} from '../../shared/cookies';
import {USER_COOKIE} from '../../shared/constants';
import {User} from '../../shared/types/generated';
import {getEmail, getIsLogin, getStep} from './auth-utils';

export const authStore = createStore<AuthStore>({
    initialValue: {
        email: getEmail(),
        isLogin: getIsLogin(),
    },
});

export const authStepStore = createStore<{ form: AuthStep }>({
    initialValue: {
        form: getStep(),
    },
});

export interface InitialStateType {
    token: null | string;
}

const INITIAL_STATE = {
    token: universalCookies.get(USER_COOKIE),
};

export const OnTokenEvent = createEvent<InitialStateType>({
    initialValue: INITIAL_STATE,
});

export const fetchAuthStore = createStore<{
    user: User | undefined | null;
}>({
    initialValue: {
        user: undefined,
    },
});
