import React from 'react';
import { Avatar, Box, Grid, Link, TextField, Typography } from '@mui/material';
import { authStepAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import { useLoginMutation } from '../../../shared/types/generated';
import { openSnackbarAction } from '../../../shared/components/snackbar/snackbar-actions';
import { TypeMessage } from '../../../shared/components/snackbar/snackbar-types';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { universalCookies } from '../../../shared/cookies';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constants';
import { Controller, useForm } from 'react-hook-form';
import { authLoginSchema } from '../auth-validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleFormErrors } from '../../../shared/utils/errors';

export const AuthSignInForm: React.FC = () => {
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(authLoginSchema, { abortEarly: false }),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [login, { loading }] = useLoginMutation({
    onCompleted: (data) => {
      universalCookies.set(USER_COOKIE, data.login?.token, COOKIES_OPTIONS);
      window.location.href = '/';
    },
    onError: (error) => {
      openSnackbarAction(error.message, TypeMessage.ERROR);
    },
  });

  const onFormSubmit = handleSubmit(
    async (dataForm) => {
      login({
        variables: {
          data: dataForm,
        },
      });
    },
    (error) => {
      handleFormErrors(error);
    },
  );

  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box display={'flex'} mb={1} alignItems={'center'} width={'100%'}>
        <Avatar sx={{ bgcolor: 'white', borderRadius: '10px' }}>
          <img alt="logo" width={40} height={40} src="/icon-log.svg" />
        </Avatar>
        <Typography
          ml={1}
          fontFamily={'Poppins'}
          component="h1"
          color={'black'}
          fontWeight={500}
          fontSize={24}
        >
          Iniciar Sesión
        </Typography>
      </Box>
      <Box
        component="form"
        width="100%"
        noValidate
        sx={{ mt: 1 }}
        onSubmit={onFormSubmit}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              name={'email'}
              control={control}
              render={({ field }) => (
                <TextField
                  style={{ margin: 0 }}
                  margin="normal"
                  variant="standard"
                  required
                  InputProps={{
                    endAdornment: (
                      <img
                        alt="logo"
                        width={20}
                        height={20}
                        src="/icon-email.svg"
                      />
                    ),
                  }}
                  fullWidth
                  id="email"
                  label="Correo electrónico"
                  autoComplete="email"
                  error={!!formState.errors.email?.message}
                  helperText={formState.errors.email?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={'password'}
              control={control}
              render={({ field }) => (
                <TextField
                  style={{ margin: 0 }}
                  margin="normal"
                  variant="standard"
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <img
                        alt="logo"
                        width={20}
                        height={20}
                        src="/icon-key.svg"
                      />
                    ),
                  }}
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={!!formState.errors.password?.message}
                  helperText={formState.errors.password?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>

        <ButtonDefault
          fullWidth
          variant="contained"
          type="submit"
          sx={{
            mt: 3,
            mb: 2,
            background: 'black' + ' !important',
            fontFamily: 'Poppins',
          }}
          isLoading={loading}
        >
          Iniciar Sesión
        </ButtonDefault>
        <Grid container rowSpacing={{ xs: 1, md: 0 }}>
          <Grid
            item
            xs={12}
            sm={5}
            display={'flex'}
            justifyContent={'start'}
            alignItems={'center'}
          >
            <Link
              variant="body2"
              sx={{
                cursor: 'pointer',
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', sm: '13px' },
                color: 'rgba(50,50,50,.85)',
                textDecoration: 'none',
              }}
              onClick={() => authStepAction(AuthStep.FORGOT_PASSWORD)}
            >
              ¿Olvidaste tu Contraseña?
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            display={'flex'}
            justifyContent={{ xs: 'start', sm: 'end' }}
            alignItems={'center'}
          >
            <Link
              variant="body2"
              sx={{
                cursor: 'pointer',
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', sm: '13px' },
                textAlign: { xs: 'start', sm: 'end' },
                color: 'rgba(50,50,50,.85)',
                textDecoration: 'none',
              }}
              onClick={() => authStepAction(AuthStep.SIGN_UP)}
            >
              ¿No tienes una cuenta? Registrarse
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
