import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  createUserInvitationDialogStore,
  fetchGlobalRegisterUsersErrorEvent,
  fetchGlobalRegisterUsersEvent,
  fetchUserByMonthsErrorEvents,
  fetchUserByMonthsEvents,
  fetchUserByProviderErrorEvents,
  fetchUserByProviderEvents,
  fetchUserCountErrorEvent,
  fetchUserCountEvent,
} from './user-events';
import axios from 'axios';
import { GlobalUserJoinSocialProvider } from './user-types';

import {BACKEND_ENDPOINT, DateFilter} from '../../shared/constants';

export const fetchUserByProviders = async (data: {
  from: string;
  to: string;
}): Promise<{ count: number; provider: string }[]> => {
  const {  to, from } = data;
  const response = await axios.get<{ count: number; provider: string }[]>(
    `${BACKEND_ENDPOINT}/api/user/provider-count`,
    {
      params: {
        to,
        from,
      },
    },
  );

  return response.data;
};

export const fetchUserByMonth = createAction(
  fetchUserByMonthsEvents,
  fetchUserByMonthsErrorEvents,
  async (data: {
    from: string;
    to: string;
    filter: DateFilter;
  }) => {
    const { from, to, filter } = data;
    const response = await axios.get<{ count: number; date: string }[]>(
      `${BACKEND_ENDPOINT}/api/user/count-by-date`,
      {
        params: {
          to,
          from,
          filter,
        },
      },
    );

    return response.data;
  },
);

export const fetchUserByProviderMetrics = createAction(
  fetchUserByProviderEvents,
  fetchUserByProviderErrorEvents,
  fetchUserByProviders,
);

export const fetchGlobalRegisterUsers = createAction(
  fetchGlobalRegisterUsersEvent,
  fetchGlobalRegisterUsersErrorEvent,
  async (data: {
    from: string;
    to: string;
    page: number;
    pageSize: number;
  }) => {
    const {  to, from, page, pageSize } = data;

    const response = await axios.get<{
      data: GlobalUserJoinSocialProvider[];
      total: number;
    }>(`${BACKEND_ENDPOINT}/api/user/all-by-filters`, {
      timeout: 60000,
      params: {
        to,
        from,
        page,
        pageSize,
      },
    });

    return response.data;
  },
);

// export const updateUserAction = createAction(
//   updateUserEvent,
//   updateUserErrorEvent,
//   async (variables: UpdateUserMutationVariables) => {
//     const response = await clientUpload.mutate<
//       UpdateUserMutation,
//       UpdateUserMutationVariables
//     >({
//       mutation: UpdateUserDocument,
//       variables,
//     });
//
//     return {
//       user: response.data?.updateUser as User,
//     };
//   },
// );

export const createUserInvitationDialogStoreAction = createStoreAction(
  createUserInvitationDialogStore,
  (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);
export const fetchUserCount = createAction(
  fetchUserCountEvent,
  fetchUserCountErrorEvent,
  async () => {
    const response = await axios.get<{ count: number }>(
      `${BACKEND_ENDPOINT}/api/user/count`,
    );
    return {
      count: response.data.count,
    };
  },
);
