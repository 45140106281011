import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import axios from 'axios';
import {
  fetchSubscriptionActiveByMonthsErrorEvents,
  fetchSubscriptionActiveByMonthsEvents,
  fetchSubscriptionActiveTotalErrorEvents,
  fetchSubscriptionActiveTotalEvents,
  fetchSubscriptionByMonthsErrorEvents,
  fetchSubscriptionByMonthsEvents,
  fetchSubscriptionByPaymentMethodErrorEvents,
  fetchSubscriptionByPaymentMethodEvents,
  fetchSubscriptionCountErrorEvent,
  fetchSubscriptionCountEvent,
  fetchSubscriptionCountStore,
  fetchSubscriptionsByPriceEvent,
  fetchSubscriptionsByPriceEventError,
  fetchSubscriptionsErrorEvent,
  fetchSubscriptionsEvent,
  fetchSubscriptionsReportErrorEvent,
  fetchSubscriptionsReportEvent,
  fetchTotalSubscriptionsReportErrorEvent,
  fetchTotalSubscriptionsReportEvent,
} from './subscription-events';
import { BACKEND_ENDPOINT, DateFilter } from '../../shared/constants';
import { CountData } from '../dashboard/dashboard-types';
import {
  GlobalSubscriptionUser,
  SubscriptionMetric,
  SubscriptionsByPrice,
  SubscriptionsEvent,
} from './subscription-types';
import { subscriptionTableFilterStore } from './subscription-store';

export const fetchSubscriptionCount = createAction(
  fetchSubscriptionCountEvent,
  fetchSubscriptionCountErrorEvent,
  async () => {
    const response = await axios.get<{ count: number }>(
      `${BACKEND_ENDPOINT}/api/user/subscription/count`,
    );
    return {
      count: response.data.count,
    };
  },
);

export const fetchSubscriptionByMonth = createAction(
  fetchSubscriptionByMonthsEvents,
  fetchSubscriptionByMonthsErrorEvents,
  async (data: { from: string; to: string; filter: DateFilter }) => {
    const { from, to, filter } = data;
    const response = await axios.get<{ count: number; date: string }[]>(
      `${BACKEND_ENDPOINT}/api/user/subscription/count-by-date`,
      {
        params: {
          to,
          from,
          filter,
        },
      },
    );

    return response.data;
  },
);

export const fetchSubscriptionsCountStoreAction = createStoreAction(
  fetchSubscriptionCountStore,
  (prev, loading: boolean, subscriptions: CountData[]) => ({
    ...prev,
    loading,
    subscriptions,
  }),
);

export const fetchSubscriptionByPaymentMethod = async (data: {
  from: string;
  to: string;
}): Promise<SubscriptionMetric[]> => {
  const { to, from } = data;
  const response = await axios.get<SubscriptionMetric[]>(
    `${BACKEND_ENDPOINT}/api/user/subscription/payment-method-count`,
    {
      params: {
        to,
        from,
      },
    },
  );

  return response.data;
};

export const fetchSubscriptionByPaymentMethodMetrics = createAction(
  fetchSubscriptionByPaymentMethodEvents,
  fetchSubscriptionByPaymentMethodErrorEvents,
  fetchSubscriptionByPaymentMethod,
);

export const fetchUserSubscriptions = createAction(
  fetchSubscriptionsEvent,
  fetchSubscriptionsErrorEvent,
  async (data: {
    from: string;
    to: string;
    page: number;
    status: string;
    paymentMethod: string;
  }) => {
    const { to, from, page, status, paymentMethod } = data;

    const response = await axios.get<SubscriptionsEvent>(
      `${BACKEND_ENDPOINT}/api/user/subscription/all-by-filters`,
      {
        timeout: 60000,
        params: {
          to,
          from,
          page,
          status,
          paymentMethod,
        },
      },
    );
    return response.data;
  },
);

export const fetchGenerateSubscriptionUserReport = createAction(
  fetchSubscriptionsReportEvent,
  fetchSubscriptionsReportErrorEvent,
  async (data: { from?: string; to?: string }) => {
    const { to, from } = data;

    const response = await axios.get<GlobalSubscriptionUser[]>(
      `${BACKEND_ENDPOINT}/api/user/subscription/generate-subscription-report`,
      {
        params: {
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const fetchGenerateTotalSubscriptionUserReport = createAction(
  fetchTotalSubscriptionsReportEvent,
  fetchTotalSubscriptionsReportErrorEvent,
  async (data: { from?: string; to?: string }) => {
    const { to, from } = data;

    const response = await axios.get<GlobalSubscriptionUser[]>(
      `${BACKEND_ENDPOINT}/api/user/subscription/generate-subscription-report`,
      {
        params: {
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const fetchSubscriptionActiveByMonth = createAction(
  fetchSubscriptionActiveByMonthsEvents,
  fetchSubscriptionActiveByMonthsErrorEvents,
  async (data: { from: string; to: string; filter: DateFilter }) => {
    const { from, to, filter } = data;
    const response = await axios.get<{ count: number; date: string }[]>(
      `${BACKEND_ENDPOINT}/api/user/subscription/count-by-date`,
      {
        params: {
          to,
          from,
          filter,
          status: 'ACTIVE',
        },
      },
    );

    return response.data;
  },
);
export const fetchSubscriptionActiveTotal = createAction(
  fetchSubscriptionActiveTotalEvents,
  fetchSubscriptionActiveTotalErrorEvents,
  async () => {
    const response = await axios.get<{ count: number }>(
      `${BACKEND_ENDPOINT}/api/user/subscription/count-by-status`,
      {
        params: {
          status: 'ACTIVE',
        },
      },
    );
    return response.data;
  },
);

export const subscriptionTableFilterStoreAction = createStoreAction(
  subscriptionTableFilterStore,
  (prev, status: string, paymentMethod: string) => ({
    ...prev,
    status,
    paymentMethod,
  }),
);

export const fetchSubscriptionsByPrice = createAction(
  fetchSubscriptionsByPriceEvent,
  fetchSubscriptionsByPriceEventError,
  async (data: {
    from: string;
    to: string;
    status: string;
    paymentMethod: string;
  }) => {
    const { to, from, status, paymentMethod } = data;

    const response = await axios.get<SubscriptionsByPrice>(
      `${BACKEND_ENDPOINT}/api/user/subscription/all-by-price`,
      {
        timeout: 60000,
        params: {
          to,
          from,
          status,
          paymentMethod,
        },
      },
    );

    return response.data;
  },
);
