/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  Upload: { input: any; output: any; }
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CancelSubscriptionInput = {
  subscription_id?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelSubscriptionResponse = {
  __typename?: 'CancelSubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type Card = {
  __typename?: 'Card';
  bank_code?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  card_number?: Maybe<Scalars['String']['output']>;
  customer_card_id?: Maybe<Scalars['String']['output']>;
  expiration_month?: Maybe<Scalars['String']['output']>;
  expiration_year?: Maybe<Scalars['String']['output']>;
  holder_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ConnectResponse = {
  __typename?: 'ConnectResponse';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type ConnectUserAppleInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectUserFacebookInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectUserGoogleInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOpenPayChargeInput = {
  deviceDataId?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOpenPayChargeResponse = {
  __typename?: 'CreateOpenPayChargeResponse';
  redirect_url?: Maybe<Scalars['String']['output']>;
};

export type CreateOpenPaySubscriptionInput = {
  deviceDataId?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['Int']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOpenPaySubscriptionResponse = {
  __typename?: 'CreateOpenPaySubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreatePaypalSubscriptionInput = {
  orderId?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaypalSubscriptionResponse = {
  __typename?: 'CreatePaypalSubscriptionResponse';
  subscription?: Maybe<Subscription>;
};

export type CreateUserSavedPostInput = {
  post_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatedAtInput = {
  gte?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DeleteUserSavedPostInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type DisableUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type DisconnectResponse = {
  __typename?: 'DisconnectResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type DisconnectUserProviderInput = {
  provider?: InputMaybe<SocialProviders>;
};

export enum Education {
  Graduate = 'GRADUATE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Superior = 'SUPERIOR'
}

export type File = {
  encoding: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
};

export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NoApply = 'NO_APPLY'
}

export type IdFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  plan?: Maybe<Plan>;
  status?: Maybe<InvoiceStatuses>;
  transaction_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export enum InvoiceStatuses {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  PaymentPending = 'PAYMENT_PENDING',
  Refunded = 'REFUNDED',
  Unpaid = 'UNPAID'
}

export type IterAuthorRelatedPostConnection = {
  __typename?: 'IterAuthorRelatedPostConnection';
  edges?: Maybe<Array<Maybe<IterAuthorRelatedPostConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type IterAuthorRelatedPostConnectionNode = {
  __typename?: 'IterAuthorRelatedPostConnectionNode';
  node?: Maybe<IterPost>;
};

export type IterAuthorRelatedPostsWhere = {
  category_id?: InputMaybe<Scalars['String']['input']>;
};

export type IterHighlightedPostsWhere = {
  section?: InputMaybe<Scalars['String']['input']>;
};

export type IterInternalSection = {
  __typename?: 'IterInternalSection';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type IterPost = {
  __typename?: 'IterPost';
  author?: Maybe<IterPostAuthor>;
  categories?: Maybe<Array<Maybe<IterPostCategory>>>;
  content?: Maybe<Array<Maybe<IterPostComponent>>>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  reactions?: Maybe<Scalars['Int']['output']>;
  readingTime?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<Maybe<IterPostTopic>>>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type IterPostAuthor = {
  __typename?: 'IterPostAuthor';
  content?: Maybe<Array<Maybe<IterPostComponent>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IterPostCategory = {
  __typename?: 'IterPostCategory';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type IterPostComponent = {
  __typename?: 'IterPostComponent';
  component?: Maybe<Array<Maybe<IterPostComponentContent>>>;
  name?: Maybe<IterPostComponentName>;
  remoteContent?: Maybe<IterRemoteContent>;
  text?: Maybe<Scalars['String']['output']>;
};

export type IterPostComponentContent = {
  __typename?: 'IterPostComponentContent';
  name?: Maybe<IterPostComponentName>;
  text?: Maybe<Scalars['String']['output']>;
};

export enum IterPostComponentName {
  Boton = 'Boton',
  Byline = 'Byline',
  Cutline = 'Cutline',
  EntrevistaPregunta = 'Entrevista_Pregunta',
  FacebookText = 'Facebook_Text',
  FichaFoto = 'Ficha_Foto',
  FichaSocial = 'Ficha_Social',
  FichaTexto = 'Ficha_Texto',
  FichaTitulo = 'Ficha_Titulo',
  FlourishText = 'Flourish_Text',
  HtmlText = 'HTML_Text',
  Headline = 'Headline',
  Image = 'Image',
  ImageText = 'Image_Text',
  ImagenColumnista = 'ImagenColumnista',
  InstagramText = 'Instagram_Text',
  Lead = 'Lead',
  LinkText = 'Link_Text',
  MultimediaText = 'Multimedia_Text',
  ParrafoLista = 'Parrafo_Lista',
  Quote = 'Quote',
  QuoteByline = 'Quote_byline',
  Subheadline = 'Subheadline',
  TeaserHeadline = 'TeaserHeadline',
  TeaserImage = 'TeaserImage',
  TeaserText = 'TeaserText',
  Text = 'Text',
  TikTokText = 'TikTok_Text',
  TituloApp = 'Titulo_App',
  TituloNews = 'Titulo_News',
  Topheadline = 'Topheadline',
  TwitterText = 'Twitter_Text',
  YoutubeText = 'Youtube_Text',
  EmailAutor = 'emailAutor',
  ImageList = 'image_list',
  LinkIntTitle = 'linkIntTitle',
  LinkIntUrl = 'linkIntURL',
  TextList = 'text_list',
  TitleList = 'title_list',
  TwitterProfile = 'twitterProfile'
}

export type IterPostConnection = {
  __typename?: 'IterPostConnection';
  edges?: Maybe<Array<Maybe<IterPostConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type IterPostConnectionNode = {
  __typename?: 'IterPostConnectionNode';
  node?: Maybe<IterPost>;
};

export type IterPostResponse = {
  __typename?: 'IterPostResponse';
  post?: Maybe<IterPost>;
};

export type IterPostTopic = {
  __typename?: 'IterPostTopic';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IterPostWhere = {
  about?: InputMaybe<Scalars['String']['input']>;
  post_id?: InputMaybe<Scalars['String']['input']>;
};

export type IterPostsBySectionResponse = {
  __typename?: 'IterPostsBySectionResponse';
  items?: Maybe<Array<Maybe<IterPost>>>;
};

export type IterPostsBySectionWhere = {
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type IterPostsResponse = {
  __typename?: 'IterPostsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<IterPost>>>;
};

export type IterPostsWhere = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type IterRemoteContent = {
  __typename?: 'IterRemoteContent';
  href?: Maybe<Scalars['String']['output']>;
  rendition?: Maybe<Scalars['String']['output']>;
};

export type IterSearchPost = {
  __typename?: 'IterSearchPost';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type IterSearchPostConnection = {
  __typename?: 'IterSearchPostConnection';
  edges?: Maybe<Array<Maybe<IterSearchPostConnectionNode>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type IterSearchPostConnectionNode = {
  __typename?: 'IterSearchPostConnectionNode';
  node?: Maybe<IterSearchPost>;
};

export type IterSearchPostsWhere = {
  category_id?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum IterSearchSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type IterSection = {
  __typename?: 'IterSection';
  id?: Maybe<Scalars['Int']['output']>;
  internals?: Maybe<Array<Maybe<IterInternalSection>>>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type IterSectionResponse = {
  __typename?: 'IterSectionResponse';
  items?: Maybe<Array<Maybe<IterSection>>>;
};

export type IterSectionsWithPosts = {
  __typename?: 'IterSectionsWithPosts';
  items?: Maybe<Array<Maybe<IterPost>>>;
  section?: Maybe<IterSection>;
};

export type IterTrendingPostsWhere = {
  section?: InputMaybe<Scalars['String']['input']>;
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MailchimpAddSubscriptionInput = {
  audience?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type MailchimpAddSubscriptionResponse = {
  __typename?: 'MailchimpAddSubscriptionResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MailchimpAudience = {
  __typename?: 'MailchimpAudience';
  audience?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_subscribed?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MailchimpAudienceResponse = {
  __typename?: 'MailchimpAudienceResponse';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<MailchimpAudience>>>;
};

export type MailchimpSubscription = {
  __typename?: 'MailchimpSubscription';
  audience?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  unique_email_id?: Maybe<Scalars['String']['output']>;
};

export type MailchimpSubscriptionResponse = {
  __typename?: 'MailchimpSubscriptionResponse';
  subscriptions?: Maybe<Array<Maybe<MailchimpSubscription>>>;
};

export type MailchimpSubscriptionWhere = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  appleSignup?: Maybe<AuthPayload>;
  cancelSubscription?: Maybe<CancelSubscriptionResponse>;
  changePassword?: Maybe<AuthPayload>;
  connectUserApple?: Maybe<ConnectResponse>;
  connectUserFacebook?: Maybe<ConnectResponse>;
  connectUserGoogle?: Maybe<ConnectResponse>;
  createOpenPayCharge?: Maybe<CreateOpenPayChargeResponse>;
  createOpenPaySubscription?: Maybe<CreateOpenPaySubscriptionResponse>;
  createPaypalSubscription?: Maybe<CreatePaypalSubscriptionResponse>;
  createUserSavedPost?: Maybe<UserSavedPost>;
  deleteUserSavedPost?: Maybe<UserSavedPost>;
  disableAccount?: Maybe<DisableAccountResponse>;
  disconnectUserProvider?: Maybe<DisconnectResponse>;
  facebookSignup?: Maybe<AuthPayload>;
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  googleOneTabSignUp?: Maybe<AuthPayload>;
  googleSignup?: Maybe<AuthPayload>;
  login?: Maybe<AuthPayload>;
  mailchimpAddSubscription?: Maybe<MailchimpAddSubscriptionResponse>;
  recaptcha?: Maybe<RecaptchaResponse>;
  signup?: Maybe<AuthPayload>;
  updateCard?: Maybe<UpdateCardResponse>;
  updateUser?: Maybe<User>;
  updateUserEmail?: Maybe<UpdateUserEmailResponse>;
};


export type MutationAppleSignupArgs = {
  data: ProviderSignupInput;
};


export type MutationCancelSubscriptionArgs = {
  data?: InputMaybe<CancelSubscriptionInput>;
};


export type MutationChangePasswordArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationConnectUserAppleArgs = {
  data: ConnectUserAppleInput;
};


export type MutationConnectUserFacebookArgs = {
  data: ConnectUserFacebookInput;
};


export type MutationConnectUserGoogleArgs = {
  data: ConnectUserGoogleInput;
};


export type MutationCreateOpenPayChargeArgs = {
  data?: InputMaybe<CreateOpenPayChargeInput>;
};


export type MutationCreateOpenPaySubscriptionArgs = {
  data?: InputMaybe<CreateOpenPaySubscriptionInput>;
};


export type MutationCreatePaypalSubscriptionArgs = {
  data?: InputMaybe<CreatePaypalSubscriptionInput>;
};


export type MutationCreateUserSavedPostArgs = {
  data?: InputMaybe<CreateUserSavedPostInput>;
};


export type MutationDeleteUserSavedPostArgs = {
  data?: InputMaybe<DeleteUserSavedPostInput>;
};


export type MutationDisableAccountArgs = {
  data?: InputMaybe<DisableUserInput>;
};


export type MutationDisconnectUserProviderArgs = {
  data: DisconnectUserProviderInput;
};


export type MutationFacebookSignupArgs = {
  data: ProviderSignupInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
  form: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
};


export type MutationGoogleOneTabSignUpArgs = {
  data: ProviderSignupInput;
};


export type MutationGoogleSignupArgs = {
  data: ProviderSignupInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationMailchimpAddSubscriptionArgs = {
  data?: InputMaybe<MailchimpAddSubscriptionInput>;
};


export type MutationRecaptchaArgs = {
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSignupArgs = {
  data: SignUpInput;
};


export type MutationUpdateCardArgs = {
  data?: InputMaybe<UpdateCardInput>;
};


export type MutationUpdateUserArgs = {
  data?: InputMaybe<UpdateUserInput>;
};


export type MutationUpdateUserEmailArgs = {
  data?: InputMaybe<UpdateUserEmailInput>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Notification = {
  __typename?: 'Notification';
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type NotificationResponse = {
  __typename?: 'NotificationResponse';
  count?: Maybe<Scalars['Int']['output']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
};

export type OpenPay = {
  __typename?: 'OpenPay';
  first_payment_price?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  open_pay_id?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<OpenPayStatuses>;
};

export enum OpenPayStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum PaymentMethod {
  Openpay = 'OPENPAY',
  Paypal = 'PAYPAL'
}

export type Paypal = {
  __typename?: 'Paypal';
  id?: Maybe<Scalars['Int']['output']>;
  paypal_id?: Maybe<Scalars['String']['output']>;
  plan_id?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<PaypalStatuses>;
};

export enum PaypalStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Plan = {
  __typename?: 'Plan';
  description?: Maybe<Scalars['String']['output']>;
  helper_text?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  open_pay?: Maybe<OpenPay>;
  paypal?: Maybe<Paypal>;
  price?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PlanStatuses>;
  sub_title?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PlanResponse = {
  __typename?: 'PlanResponse';
  items?: Maybe<Array<Maybe<Plan>>>;
};

export enum PlanStatuses {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type PlanWhereInput = {
  status?: InputMaybe<PaypalStatuses>;
};

export type PlansWhereInput = {
  status?: InputMaybe<PaypalStatuses>;
};

export type ProviderSignupInput = {
  token: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  mailchimpAudiences?: Maybe<MailchimpAudienceResponse>;
  mailchimpSubscriptions?: Maybe<MailchimpSubscriptionResponse>;
  notificationList?: Maybe<NotificationResponse>;
  plan?: Maybe<Plan>;
  plans?: Maybe<PlanResponse>;
  userProviders?: Maybe<UserProviderPayload>;
  users?: Maybe<UsersResponse>;
};


export type QueryMailchimpSubscriptionsArgs = {
  where?: InputMaybe<MailchimpSubscriptionWhere>;
};


export type QueryNotificationListArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotificationsWhere>;
};


export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPlansArgs = {
  where?: InputMaybe<PlansWhereInput>;
};


export type QueryUserProvidersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserProvidersWhere>;
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UsersWhereInput>;
};

export type RecaptchaResponse = {
  __typename?: 'RecaptchaResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RolesOnUsers = {
  __typename?: 'RolesOnUsers';
  role?: Maybe<UserRole>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

export type SignUpInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export enum SocialProviders {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  System = 'SYSTEM'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancel_date?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  customer_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  is_cancel?: Maybe<Scalars['Boolean']['output']>;
  payment_method?: Maybe<PaymentMethod>;
  period_end_date?: Maybe<Scalars['String']['output']>;
  period_start_date?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Plan>;
  status?: Maybe<SubscriptionStatuses>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum SubscriptionStatuses {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Inactive = 'INACTIVE'
}

export type UpdateCardInput = {
  card_number?: InputMaybe<Scalars['String']['input']>;
  cvv2?: InputMaybe<Scalars['String']['input']>;
  device_session_id?: InputMaybe<Scalars['String']['input']>;
  expiration_month?: InputMaybe<Scalars['String']['input']>;
  expiration_year?: InputMaybe<Scalars['String']['input']>;
  holder_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCardResponse = {
  __typename?: 'UpdateCardResponse';
  card?: Maybe<Card>;
};

export type UpdateUserEmailInput = {
  new_email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserEmailResponse = {
  __typename?: 'UpdateUserEmailResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateUserInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  user?: InputMaybe<UserInput>;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  card?: Maybe<Card>;
  created_at?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Education>;
  email: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  id: Scalars['Int']['output'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  name: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  roles_on_users?: Maybe<Array<Maybe<RolesOnUsers>>>;
  social_providers?: Maybe<Array<Maybe<UserSocialProviders>>>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Education>;
  email: Scalars['String']['input'];
  email_verified_at?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  remember_token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrderInput = {
  created_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type UserProviderPayload = {
  __typename?: 'UserProviderPayload';
  count?: Maybe<Scalars['Int']['output']>;
  userProviders?: Maybe<Array<Maybe<UserSocialProviders>>>;
};

export type UserProvidersWhere = {
  created_at?: InputMaybe<CreatedAtInput>;
  provider?: InputMaybe<SocialProviders>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<UserRoles>;
};

export enum UserRoles {
  Administrator = 'ADMINISTRATOR',
  Suscriptor = 'SUSCRIPTOR',
  User = 'USER'
}

export type UserSavedIterPostsResponse = {
  __typename?: 'UserSavedIterPostsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  posts?: Maybe<Array<Maybe<IterPost>>>;
};

export type UserSavedIterPostsWhere = {
  post_id?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  user_saved_post_folder_id?: InputMaybe<Scalars['Int']['input']>;
};

export type UserSavedPost = {
  __typename?: 'UserSavedPost';
  created_at?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type UserSavedPostRestApi = {
  __typename?: 'UserSavedPostRestApi';
  id?: Maybe<Scalars['Int']['output']>;
  post_content?: Maybe<Scalars['String']['output']>;
  post_date?: Maybe<Scalars['String']['output']>;
  post_id?: Maybe<Scalars['Int']['output']>;
  post_image?: Maybe<Scalars['String']['output']>;
  post_name?: Maybe<Scalars['String']['output']>;
  post_terms?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  post_title?: Maybe<Scalars['String']['output']>;
  post_type?: Maybe<Scalars['String']['output']>;
  post_uri?: Maybe<Scalars['String']['output']>;
  post_url?: Maybe<Scalars['String']['output']>;
  sub_post_type?: Maybe<Scalars['String']['output']>;
};

export type UserSavedPostsResponse = {
  __typename?: 'UserSavedPostsResponse';
  count?: Maybe<Scalars['Int']['output']>;
  posts?: Maybe<Array<Maybe<UserSavedPostRestApi>>>;
};

export type UserSavedPostsWhere = {
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type UserSocialProviders = {
  __typename?: 'UserSocialProviders';
  created_at?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  primary?: Maybe<Scalars['Boolean']['output']>;
  provider?: Maybe<SocialProviders>;
  provider_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  url_register?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersPayload = {
  __typename?: 'UsersPayload';
  count?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count?: Maybe<Scalars['Int']['output']>;
  items?: Maybe<Array<Maybe<User>>>;
};

export type UsersWhere = {
  created_at?: InputMaybe<CreatedAtInput>;
  name?: InputMaybe<StringFilter>;
};

export type UsersWhereInput = {
  created_at?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<NestedStringFilter>;
  name?: InputMaybe<NestedStringFilter>;
};

export type DisableAccountResponse = {
  __typename?: 'disableAccountResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type IterRankingPostsWhere = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};

export type NotificationsWhere = {
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type SignupMutationVariables = Exact<{
  data: SignUpInput;
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  redirectUrl: Scalars['String']['input'];
  form: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordPayload', success: boolean | null } | null };

export type ChangePasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'AuthPayload', token: string | null, user: { __typename?: 'User', id: number } | null } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: number, name: string, email: string, avatar: string | null, gender: Gender | null, birthday: string | null, education: Education | null, social_providers: Array<{ __typename?: 'UserSocialProviders', id: number, provider: SocialProviders | null, provider_id: string | null, primary: boolean | null } | null> | null, roles_on_users: Array<{ __typename?: 'RolesOnUsers', role_id: number | null, role: { __typename?: 'UserRole', id: number | null, name: UserRoles | null } | null } | null> | null, subscriptions: Array<{ __typename?: 'Subscription', cancel_date: string | null, status: SubscriptionStatuses | null, payment_method: PaymentMethod | null, period_end_date: string | null, period_start_date: string | null, is_cancel: boolean | null, id: number | null, plan: { __typename?: 'Plan', title: string | null, description: string | null, sub_title: string | null, price: string | null } | null } | null> | null, invoices: Array<{ __typename?: 'Invoice', id: number | null, transaction_id: string | null, status: InvoiceStatuses | null, created_at: string | null, updated_at: string | null, plan: { __typename?: 'Plan', title: string | null, description: string | null, sub_title: string | null, price: string | null } | null } | null> | null, card: { __typename?: 'Card', type: string | null, brand: string | null, card_number: string | null, holder_name: string | null, expiration_year: string | null, expiration_month: string | null, bank_name: string | null, bank_code: string | null, customer_card_id: string | null } | null } | null };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UsersWhereInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrderInput> | UserOrderInput>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number | null, items: Array<{ __typename?: 'User', id: number, name: string, avatar: string | null, birthday: string | null, created_at: string | null, email: string, gender: Gender | null, updated_at: string | null, roles_on_users: Array<{ __typename?: 'RolesOnUsers', role: { __typename?: 'UserRole', id: number | null, name: UserRoles | null } | null } | null> | null, social_providers: Array<{ __typename?: 'UserSocialProviders', id: number, primary: boolean | null, provider: SocialProviders | null, url_register: string | null } | null> | null, subscriptions: Array<{ __typename?: 'Subscription', id: number | null, status: SubscriptionStatuses | null, plan: { __typename?: 'Plan', id: number | null, title: string | null } | null } | null> | null } | null> | null } | null };


export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($data: SignUpInput!) {
  signup(data: $data) {
    user {
      id
    }
    token
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!, $redirectUrl: String!, $form: String!) {
  forgotPassword(email: $email, redirectUrl: $redirectUrl, form: $form) {
    success
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($email: String!, $password: String!, $token: String!) {
  changePassword(email: $email, password: $password, token: $token) {
    user {
      id
    }
    token
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    id
    name
    email
    avatar
    gender
    birthday
    education
    social_providers {
      id
      provider
      provider_id
      primary
    }
    roles_on_users {
      role_id
      role {
        id
        name
      }
    }
    subscriptions {
      plan {
        title
        description
        sub_title
        price
      }
      cancel_date
      status
      payment_method
      period_end_date
      period_start_date
      is_cancel
      id
    }
    invoices {
      id
      transaction_id
      status
      created_at
      updated_at
      plan {
        title
        description
        sub_title
        price
      }
    }
    card {
      type
      brand
      card_number
      holder_name
      expiration_year
      expiration_month
      bank_name
      bank_code
      customer_card_id
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const UsersDocument = gql`
    query Users($where: UsersWhereInput, $take: Int, $skip: Int, $orderBy: [UserOrderInput!]) {
  users(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    count
    items {
      id
      name
      roles_on_users {
        role {
          id
          name
        }
      }
      social_providers {
        id
        primary
        provider
        url_register
      }
      avatar
      birthday
      created_at
      email
      gender
      subscriptions {
        id
        status
        plan {
          id
          title
        }
      }
      updated_at
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export function useUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<typeof useUsersSuspenseQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;