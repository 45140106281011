import React, { useEffect, useState } from 'react';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { DashboardCardItem } from '../DashboardCardItem';
import { getSubscriptionActiveDateFilterTitleText } from '../../dashboard-utils';
import { fetchSubscriptionActiveByMonth } from '../../../subscription/subscription-actions';

export const DashboardTotalSubscriptionActiveItem: React.FC = () => {
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const [count, setCount] = useState(0);

  const [callAction, loading] = useCallAction(fetchSubscriptionActiveByMonth, {
    onCompleted: (data) => {
      setCount(
        data.reduce((acc, curr) => {
          return acc + curr.count;
        }, 0),
      );
    },
  });

  useEffect(() => {
    callAction({
      from,
      to,
      filter: filter,
    });
  }, [callAction, from, to, filter]);

  return (
    <DashboardCardItem
      title={getSubscriptionActiveDateFilterTitleText(from, to, filter)}
      content={count}
      loading={loading}
    />
  );
};
