import moment from 'moment';
import 'moment/locale/es';
import { DateFilter, DEFAULT_TIMEZONE } from '../../shared/constants';

moment.locale('es');

export const getDateFilterText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  const ranges = `( ${moment(from).utcOffset(0).format('DD-MM')} - ${moment(to).utcOffset(0).format('DD-MM')} )`;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = `Mes actual ${ranges}`;
      break;
    case DateFilter.CURRENT_WEEK:
      text = `Esta semana ${ranges}`;
      break;
    case DateFilter.LAST_WEEK:
      text = `Semana anterior ${ranges}`;
      break;
    case DateFilter.LAST_MONTH:
      text = `Mes anterior ${ranges}`;
      break;
    case DateFilter.YESTERDAY:
      text = `Ayer ${ranges}`;
      break;
    case DateFilter.CURRENT_YEAR:
      text = `Este año ${ranges}`;
      break;
    case DateFilter.DAY:
      text = `Desde ${moment(from).utcOffset(0).format('DD-MM')} hasta ${moment(to).utcOffset(0).format('DD-MM')}`;
      break;
    case DateFilter.CUSTOM:
      text = `Desde ${moment(from).utcOffset(0).format('DD-MM')} hasta ${moment(to).utcOffset(0).format('DD-MM')}`;
      break;
    default:
      text = `Hoy ${ranges}`;
      break;
  }

  return text;
};

export const getDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Usuarios registrados este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Usuarios registrados esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Usuarios registrados la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Usuarios registrados el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Usuarios registrados ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Usuarios registrados este año';
      break;
    case DateFilter.MONTH:
      text = `Usuarios registrados  Desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.DAY:
      text = `Usuarios registrados  Desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.YEAR:
      text = `Usuarios registrados  Desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Usuarios registrados hoy';
      break;
  }

  return text;
};


export const getSubscriptionDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Suscripciones registradas este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Suscripciones registradas esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Suscripciones registradas la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Suscripciones registradas el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Suscripciones registradas ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Suscripciones registradas este año';
      break;
    case DateFilter.CUSTOM:
      text = `Suscripciones registradas desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Suscripciones registradas hoy';
      break;
  }

  return text;
};

export const getSubscriptionActiveDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Suscripciones activas este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Suscripciones activas esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Suscripciones activas la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Suscripciones activas el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Suscripciones activas ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Suscripciones activas este año';
      break;
    case DateFilter.CUSTOM:
      text = `Suscripciones activas desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Suscripciones activas hoy';
      break;
  }

  return text;
};

export const subscriptionStatus = (status: string): string => {
  let s: string;

  switch (status) {
    case 'INACTIVE':
      s = 'Inactivo';
      break;
    case 'CANCELLED':
      s = 'Cancelado';
      break;
    default:
      s = 'Activo';
      break;
  }

  return s;
};


export const getSubscriptionTotalDateFilterTitleText = (
  from: string,
  to: string,
  filter: DateFilter,
): string => {
  let text: string;

  switch (filter) {
    case DateFilter.CURRENT_MONTH:
      text = 'Ganancias totales este mes';
      break;
    case DateFilter.CURRENT_WEEK:
      text = 'Ganancias totales esta semana';
      break;
    case DateFilter.LAST_WEEK:
      text = 'Ganancias totales la semana anterior';
      break;
    case DateFilter.LAST_MONTH:
      text = 'Ganancias totales el mes anterior';
      break;
    case DateFilter.YESTERDAY:
      text = 'Ganancias totales ayer';
      break;
    case DateFilter.CURRENT_YEAR:
      text = 'Ganancias totales este año';
      break;
    case DateFilter.DAY:
      text = `Ganancias totales desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    case DateFilter.CUSTOM:
      text = `Ganancias totales desde ${moment(from).tz(DEFAULT_TIMEZONE).format('DD-MM')} hasta ${moment(to).tz('America/Mexico_City').format('DD-MM')}`;
      break;
    default:
      text = 'Ganancias totales hoy';
      break;
  }

  return text;
};
