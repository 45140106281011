import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth/auth-hook';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { DashboardCardItem } from '../DashboardCardItem';
import { fetchSubscriptionCount } from '../../../subscription/subscription-actions';
import { Box, Typography } from '@mui/material';

export const DashboardTotalSubscriptionItem: React.FC = () => {
  const [total, setTotal] = useState(0);
  const [callAction, loading] = useCallAction(fetchSubscriptionCount, {
    onCompleted: (data) => {
      setTotal(data.count || 0);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  useEffect(() => {
    callAction();
  }, [callAction]);

  return (
    <DashboardCardItem
      title={'Total de suscripciones'}
      content={total}
      loading={loading}
    />
  );
};
