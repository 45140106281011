import React from 'react';
import { Box, Grid, Tabs, Tab, tabClasses, Container } from '@mui/material';
import { DashboardGeneralTab } from './components/general/DashboardGeneralTab';
import { useAuth } from '../auth/auth-hook';
import { RoleAuthorizationAdmin } from '../rbac/RoleAuthorization';
import { RBACPermissions } from '../rbac/rbac-types';
import { DashboardSubscriptionTab } from './components/subscriptions/DashboardSubscriptionTab';
import { styled } from '@mui/styles';
import { CustomHeader } from '../../shared/components/layout/CustomHeader';
import { PROJECT_COLOR } from '../../shared/constants';

export const DashboardTenant: React.FC = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { user } = useAuth();
  const handleChange = (
    event: React.SyntheticEvent | null,
    newValue: number | null,
  ): void => {
    setCurrentTab(newValue ?? 0);
  };
  const TabItem = styled(Tab)({
    position: 'relative',
    borderRadius: '30px !important',
    textAlign: 'center',
    transition: 'all .5s',
    padding: '8px 10px !important',
    color: '#BBBBBB',
    height: '35px !important',
    margin: '6px 2px',
    float: 'none',
    lineHeight: '14px',
    fontSize: '14px',
    fontWeight: '700',
    minHeight: '38px',
    textTransform: 'capitalize',
    [`&.${tabClasses.selected}, &:hover`]: {
      color: PROJECT_COLOR ?? '#000000',
      backgroundColor: (PROJECT_COLOR ?? '#000000') + '20 !important',
    },
  });
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            px: 2,
          },
        })}
      >
        <CustomHeader
          title={`Bienvenido de vuelta, ${user?.name}`}
          subtitle={
            'Mide el IMPACTO de tus publicitaciones, registros, choques y tráfico del sitio web.'
          }
        />

        <Container
          maxWidth={'lg'}
          style={{ borderRadius: '16px', background: 'white' }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              sx={{
                padding: { sm: '0px 10px !important' },

                ['& .MuiTabs-indicator']: {
                  display: 'none !important',
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="scrollable auto tabs example"
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <TabItem label="General" />
              <TabItem label="Suscripciones" />
            </Tabs>
          </Box>
          <Box sx={{ padding: { xs: '0px', sm: '16px' } }}>
            {currentTab === 0 && <DashboardGeneralTab />}
            {currentTab === 1 && (
              <RoleAuthorizationAdmin
                render={() => <DashboardSubscriptionTab />}
                permission={RBACPermissions.TENANT_DASHBOARD_SUBSCRIPTION}
              />
            )}
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};
