import React, { useEffect } from 'react';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { fetchGenerateWinnerReport, winnerNotes } from '../../dashboard-actions';
import { useAuth } from '../../../auth/auth-hook';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { DashboardWinnerNotesPie } from '../DashboardWinnerNotesPie';
import { DashboardWinnerNotes } from '../DashboardWinnerNotes';
import {
  changeDashboardDateFilterStore,
  changeWinnerNotesOrderStore,
} from '../../dashboard-events';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import * as XLSX from 'xlsx';
import { getDateFilterText } from '../../dashboard-utils';
import { saveAs } from 'file-saver';
import { useStylesCustom } from '../DashboardCardItem';
import { PRIMARY_COLOR } from '../../../../shared/css/theme';

export const DashboardWinnerNoteContainer: React.FC = () => {
  const classes = useStylesCustom();
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const { order } = useStore(changeWinnerNotesOrderStore);

  const [callWinnerNotes, loading] = useCallAction(winnerNotes);
  const [callReport, loadingReport] = useCallAction(fetchGenerateWinnerReport, {
    onCompleted: ({ report }) => {
      console.log({ report });
      const worksheet = XLSX.utils.json_to_sheet(
        report.map((r) => ({
          url: r.url,
          cantidad: r.count,
        })),
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${getDateFilterText(from, to, filter)}`,
      );
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      saveAs(blob, `Reporte ${getDateFilterText(from, to, filter)}.xlsx`);
    },
  });

  useEffect(() => {
    callWinnerNotes({
      from,
      to,
      order,
    });
  }, [ callWinnerNotes, from, to, order]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={1}>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              component={'div'}
              sx={{ marginBottom: { xs: '10px', md: '0px' } }}
              fontSize={20}
              fontWeight={700}
            >
              Notas Ganadoras
            </Typography>
            <ButtonDefault
              onClick={() =>
                callReport({
                  from,
                  to,
                })
              }
              startIcon={
                !loadingReport ? (
                  <img src="/icon-clip.svg" alt="clip" width={14} height={14} />
                ) : null
              }
              isLoading={loadingReport}
              style={{
                width: 195,
                height: 40,
                textTransform: 'inherit',
                fontWeight: 700,
                background: PRIMARY_COLOR ?? 'black',
              }}
            >
              Descargar Reporte
            </ButtonDefault>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 2,
            md: 1,
          }}
        >
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
            }}
          >
            <CardContent className={classes.content}>
              <DashboardWinnerNotes loading={loading} />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          order={{
            xs: 1,
            md: 2,
          }}
        >
          <Card
            elevation={2}
            style={{
              border: '1px solid #BBBBBB',
              padding: '16px',
              borderRadius: '16px',
              boxShadow: '0px 0px 8px 0px #00000014',
            }}
          >
            <CardContent className={classes.content}>
              <DashboardWinnerNotesPie />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
