import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth/auth-hook';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { DashboardCardItem } from '../DashboardCardItem';
import { fetchSubscriptionActiveTotal } from '../../../subscription/subscription-actions';
import { fetchSubscriptionActiveTotalEvents } from '../../../subscription/subscription-events';

export const DashboardTotalActiveSubscriptionWithoutFilterItem: React.FC =
  () => {
    const { count } = useEvent(fetchSubscriptionActiveTotalEvents);
    const [callAction, loading] = useCallAction(fetchSubscriptionActiveTotal, {
      onError: (error) => {
        console.log(error.message);
      },
    });

    useEffect(() => {
        callAction();

    }, [callAction]);

    return (
      <DashboardCardItem
        title='Total de suscripciones activas'
        content={count}
        loading={loading}
      />
    );
  };
