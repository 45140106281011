import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { CARD_HEIGHT_DASHBOARD } from '../../../../shared/constants';
import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import {
  changeDashboardDateFilterStore,
} from '../../dashboard-events';
import { fetchUserByProviderMetrics } from '../../../user/user-actions';
import es from 'apexcharts/dist/locales/es.json';
import { useStylesCustom } from '../DashboardCardItem';

export const DashboardUserProviderBar: React.FC = () => {
  const classes = useStylesCustom();
  const { from, to } = useStore(changeDashboardDateFilterStore);
  const [providers, setProviders] = useState<
    { provider: string; count: number }[]
  >([]);

  const categories = ['GOOGLE', 'SYSTEM', 'FACEBOOK', 'APPLE'];

  const [callFetchUser] = useCallAction(fetchUserByProviderMetrics, {
    onCompleted: (data) => {
      setProviders(data);
    },
  });

  useEffect(() => {
    callFetchUser({
      from,
      to,
    });
  }, [callFetchUser, from, to]);

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Total',
        data: categories.map((category) => {
          const item = providers.find(
            (provider) => category === provider.provider,
          );

          if (item) return item?.count || 0;

          return 0;
        }),
      },
    ],
    options: {
      colors: ['#fe0000', '#fe0000'],
      chart: {
        id: 'user-registered-chart',
        height: 300,
        type: 'bar',
        defaultLocale: 'es',
        locales: [es],
      },
      plotOptions: {
        bar: {
          // borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: string) {
          return val.toLocaleString();
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },

      xaxis: {
        categories: categories,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: number) {
            return val.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Card
      elevation={2}
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.header}
        title="Usuarios registrados por canal"
      />

      <CardContent
        className={classes.content}
        style={{ height: CARD_HEIGHT_DASHBOARD }}
      >
        {(DATA.series?.length ?? 0) > 0 ? (
          <Chart
            options={DATA.options}
            series={DATA.series}
            type="bar"
            height={'100%'}
          />
        ) : null}
      </CardContent>
    </Card>
  );
};
