import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  changeDashboardDateFilterStore,
  changeDateFilterStore,
  fetchUsersCountStore,
  winnerNoteErrorEvents,
  winnerNoteErrorPaginateEvents,
  winnerNoteEvents,
  winnerNotePaginateEvents,
  changeGoogleDateFilterStore,
  selectedSectionStore,
  fetchGenerateUserReportEvent,
  fetchGenerateUserReportErroEvent,
  fetchGenerateWinnerReportEvent,
  fetchGenerateWinnerReportErroEvent,
} from './dashboard-events';
import { BACKEND_ENDPOINT, DateFilter } from '../../shared/constants';
import axios from 'axios';
import { CountData, GenerateUserReport, WinnerNote } from './dashboard-types';

export const changeDateFilterStoreAction = createStoreAction(
  changeDateFilterStore,
  (prev, date: DateFilter) => ({
    ...prev,
    date,
  }),
);

export const changeDashboardDateFilterStoreAction = createStoreAction(
  changeDashboardDateFilterStore,
  (prev, from: string, to: string, filter: DateFilter) => ({
    ...prev,
    from,
    to,
    filter,
  }),
);

export const winnerNotes = createAction(
  winnerNoteEvents,
  winnerNoteErrorEvents,
  async (data: {
    from: string;
    to: string;
    order: 'asc' | 'desc';
  }) => {
    const { to, from, order } = data;

    const response = await axios.get<{
      data: WinnerNote[];
      total: number;
    }>(`${BACKEND_ENDPOINT}/api/user/winner-notes`, {
      params: {
        to,
        from,
        order,
      },
    });

    return response.data;
  },
);

export const winnerNotesPaginate = createAction(
  winnerNotePaginateEvents,
  winnerNoteErrorPaginateEvents,
  async (data: {
    from: string;
    to: string;
    page: number;
    pageSize: number;
    order: 'asc' | 'desc';
  }) => {
    const { to, from, page, order, pageSize } = data;

    const response = await axios.get<{
      data: WinnerNote[];
      total: number;
    }>(`${BACKEND_ENDPOINT}/api/user/winner-notes`, {
      params: {
        to,
        from,
        page,
        order,
        pageSize,
      },
    });

    return response.data;
  },
);

export const fetchUsersCountStoreAction = createStoreAction(
  fetchUsersCountStore,
  (prev, loading: boolean, users: CountData[]) => ({
    ...prev,
    loading,
    users,
  }),
);

export const changeGoogleDateFilterStoreAction = createStoreAction(
  changeGoogleDateFilterStore,
  (prev, from: string, to: string, filter: DateFilter) => ({
    ...prev,
    from,
    to,
    filter,
  }),
);

export const selectedSectionStoreAction = createStoreAction(
  selectedSectionStore,
  (prev, section: string) => ({
    ...prev,
    section,
  }),
);

export const fetchGenerateUserReport = createAction(
  fetchGenerateUserReportEvent,
  fetchGenerateUserReportErroEvent,
  async (data: {
    from: string;
    to: string;
  }) => {
    const { to, from } = data;

    const response = await axios.get<GenerateUserReport[]>(
      `${BACKEND_ENDPOINT}/api/user/generate-user-report`,
      {
        params: {
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);

export const fetchGenerateWinnerReport = createAction(
  fetchGenerateWinnerReportEvent,
  fetchGenerateWinnerReportErroEvent,
  async (data: {
    from: string;
    to: string;
  }) => {
    const { to, from } = data;

    const response = await axios.get<WinnerNote[]>(
      `${BACKEND_ENDPOINT}/api/user/generate-winner-note-report`,
      {
        params: {
          to,
          from,
        },
      },
    );

    return {
      report: response.data,
    };
  },
);
