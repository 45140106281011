import React, { useEffect, useState } from 'react';
import { TableDefaultSticky } from '../../../../shared/components/table/TableDefaultSticky';
import { TableColumnType } from '../../../../shared/components/table/table-types';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { ButtonDefault } from '../../../../shared/components/ButtonDefault';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { getDateFilterText, subscriptionStatus } from '../../dashboard-utils';
import { fetchSubscriptionsEvent } from '../../../subscription/subscription-events';
import {
  fetchGenerateSubscriptionUserReport,
  fetchUserSubscriptions,
} from '../../../subscription/subscription-actions';
import { GlobalSubscriptionTable } from '../../../subscription/subscription-types';
import { SubscriptionTableFilter } from '../../../subscription/components/SubscriptionTableFilter';
import { subscriptionTableFilterStore } from '../../../subscription/subscription-store';
import { useStylesCustom } from '../DashboardCardItem';
import { PRIMARY_COLOR } from '../../../../shared/css/theme';

export const DashboardSubscriptionsTable: React.FC = () => {
  const classes = useStylesCustom();
  const { data, total, paymentMethods } = useEvent(fetchSubscriptionsEvent);
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);
  const [callFetchUsers, loading] = useCallAction(fetchUserSubscriptions);
  const { status, paymentMethod } = useStore(subscriptionTableFilterStore);

  const [callReport, loadingReport] = useCallAction(
    fetchGenerateSubscriptionUserReport,
    {
      onCompleted: ({ report }) => {
        const worksheet = XLSX.utils.json_to_sheet(
          report.map((r) => {
            return {
              ID: r.id,
              fecha: moment(r.period_start_date || r.date).format(
                'YYYY-MM-DD HH:mm',
              ),
              nombre: r.user ? r.user.name || '' : '',
              correo: r.user ? r.user.email || '' : '',
              plan: r.plan.title,
              status: subscriptionStatus(r.status),
              subscription_id: r.transaction_id || '',
              user_id: r.user ? r.user.id || '' : '',
            };
          }),
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `${getDateFilterText(from, to, filter)}`,
        );
        const excelBuffer = XLSX.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const blob = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });
        saveAs(blob, `Reporte ${getDateFilterText(from, to, filter)}.xlsx`);
      },
    },
  );


  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  useEffect(() => {
    callFetchUsers({
      from,
      to,
      page: pagination.page - 1,
      status,
      paymentMethod,
    });

  }, [
    callFetchUsers,
    from,
    pagination.page,
    to,
    status,
    paymentMethod,
  ]);

  const COLUMNS: TableColumnType<GlobalSubscriptionTable>[] = [
    {
      columnName: 'FECHA DE SUSCRIPCIÓN',
      columnValue: null,
      columnComponent: (item) => (
        <Box>
          {(item.period_start_date as string) ||
            (item.date as string) ||
            item.created_at}
        </Box>
      ),
      sortableField: 'period_start_date',
    },
    {
      columnName: 'NOMBRE',
      columnValue: null,

      columnComponent: (item) => <Box textAlign="left">{item.user_name}</Box>,
      sortableField: 'user_name',
    },
    {
      columnName: 'EMAIL',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.user_email as string}</Box>
      ),
      sortableField: 'user_email',
    },
    {
      columnName: 'PLAN',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.plan_title as string}</Box>
      ),
      sortableField: 'plan_title',
    },
    {
      columnName: 'N° DE SUSCRIPCIÓN',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.transaction_id as string}</Box>
      ),
      sortableField: 'transaction_id',
    },
    {
      columnName: 'MÉTODO DE PAGO',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{item.payment_method as string}</Box>
      ),
      sortableField: 'payment_method',
    },
    {
      columnName: 'STATUS',
      columnValue: null,

      columnComponent: (item) => (
        <Box textAlign="left">{subscriptionStatus(item.status)}</Box>
      ),
      sortableField: 'status',
    },
  ];

  return (
    <>
      <Box
        marginY={2}
        sx={{
          display: { xs: 'block', md: 'flex' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          component={'div'}
          sx={{ marginBottom: { xs: '10px', md: '0px' } }}
          fontSize={20}
          fontWeight={700}
        >
          Suscripciones
        </Typography>
        <Box>
          <ButtonDefault
            onClick={() =>
              callReport({
                from,
                to,
              })
            }
            startIcon={
              !loadingReport ? (
                <img src="/icon-clip.svg" alt="clip" width={14} height={14} />
              ) : null
            }
            isLoading={loadingReport}
            style={{
              width: 195,
              height: 40,
              textTransform: 'inherit',
              fontWeight: 700,
              background: PRIMARY_COLOR ?? 'black',
            }}
          >
            Descargar reporte
          </ButtonDefault>
          <SubscriptionTableFilter
            paymentMethodFilters={paymentMethods || []}
          />
        </Box>
      </Box>
      <Card
        elevation={2}
        style={{
          border: '1px solid #BBBBBB',
          padding: '16px',
          borderRadius: '16px',
          boxShadow: '0px 0px 8px 0px #00000014',
        }}
      >
        <CardContent className={classes.content}>
          <TableDefaultSticky
            count={total}
            items={data}
            columns={COLUMNS}
            page={pagination.page}
            pageSize={pagination.pageSize}
            loading={loading}
            messageLoading={'Cargando usuarios registrados...'}
            handleChangePage={(page) => {

              setPagination({
                ...pagination,
                page: page + 1,
              });
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};
