import React, { useEffect, useState } from 'react';
import { Provider } from './AuthContext';
import { useEvent, useStore } from '@cobuildlab/react-simple-state';
import { fetchAuthStore, OnTokenEvent } from './auth-events';
import { useCurrentUserLazyQuery } from '../../shared/types/generated';
import { universalCookies } from '../../shared/cookies';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';
import { fetchAuthStoreAction } from './auth-actions';

type AuthProviderProps = {
  children?: React.ReactNode;
};
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticate] = useState<boolean>(
    !!universalCookies.get(USER_COOKIE),
  );
  const { user } = useStore(fetchAuthStore);
  const { token } = useEvent(OnTokenEvent);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [currentUser] = useCurrentUserLazyQuery({
    onCompleted: ({ currentUser: currentUserData }) => {

      fetchAuthStoreAction(currentUserData);
      setIsAuthenticate(true);
      setLoading(false);
      console.log('test');
    },
    onError: () => {
      console.log('error');
      universalCookies.remove(USER_COOKIE, COOKIES_OPTIONS);
      OnTokenEvent.dispatch({ token: null });
      navigate('/auth');
    },
  });
  useEffect(() => {
    if (token) {
      currentUser();
    } else {
      setLoading(false);
    }
  }, [currentUser, token]);

  const values = {
    loading,
    isAuthenticated,
    user,
    refetch: () => {
      currentUser({
        fetchPolicy: 'network-only',
      });
    },
  };

  return <Provider value={values}>{children}</Provider>;
};
