import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  fetchTenantSubscriptionEvent,
  fetchTenantSubscriptionEventError,
  TenantSubscriptionListEvent,
  TenantSubscriptionListEventError,
} from './tenant-subs-plan-events';
import axios from 'axios';
import {
  TenantSubscriptionListType,
  TenantSubscriptionPlan,
} from './tenant-subs-plan-types';
import { tenantSubscriptionPlansFilterStore } from './tenant-subs-plan-store';
import { BACKEND_ENDPOINT } from '../../shared/constants';

export const tenantSubscriptionPlansFilterStoreAction = createStoreAction(
  tenantSubscriptionPlansFilterStore,
  (prev, status: string, isPremium: string) => ({
    ...prev,
    status,
    isPremium,
  }),
);

export const fetchTenantSubscriptionList = createAction(
  TenantSubscriptionListEvent,
  TenantSubscriptionListEventError,
  async (data: {
    page: number;
    pageSize: number;
    status: string;
    isPremium: string;
  }) => {
    const { page, pageSize, status, isPremium } = data;
    const url = `${BACKEND_ENDPOINT}/api/plans`;

    const response = await axios.get<TenantSubscriptionListType>(url, {
      params: { page, pageSize, status, isPremium },
      timeout: 60000,
    });

    return response.data;
  },
);

export const fetchTenantSubscriptionPlan = createAction(
  fetchTenantSubscriptionEvent,
  fetchTenantSubscriptionEventError,
  async (data: { id: string }) => {
    const { id } = data;
    const url = `${BACKEND_ENDPOINT}/api/plans/${id}`;

    const response = await axios.get<TenantSubscriptionPlan>(url, {
      timeout: 60000,
    });

    return response.data;
  },
);
