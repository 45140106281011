import React from 'react';
import { Card, CardContent, CardHeader, Box } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import {
  CARD_HEIGHT_DASHBOARD,
  DateFilter,
} from '../../../../shared/constants';
import { useStore } from '@cobuildlab/react-simple-state';
import { changeDashboardDateFilterStore } from '../../dashboard-events';
import { generateCategoryMonth } from '../../../../shared/utils';
import moment from 'moment';
import es from 'apexcharts/dist/locales/es.json';
import { Loading } from '../../../../shared/components/Loading';
import { fetchSubscriptionCountStore } from '../../../subscription/subscription-events';
import { CountData } from '../../dashboard-types';
import { useStylesCustom } from '../DashboardCardItem';
import { CustomSizes } from '../../../../shared/components/loading-types';
import { PRIMARY_COLOR } from '../../../../shared/css/theme';

moment.locale('es');
export const DashboardSubscriptionByMonthBar: React.FC = () => {
  const classes = useStylesCustom();
  const { from, to, filter } = useStore(changeDashboardDateFilterStore);

  const { loading, subscriptions } = useStore(fetchSubscriptionCountStore);

  const categories = generateCategoryMonth(filter, from, to, subscriptions);

  const sanitizeData: CountData[] = categories.map((date) => {
    const subscription = subscriptions.find((subs) => subs.date === date);

    if (subscription) {
      return {
        count: subscription.count,
        date: subscription.date,
      };
    }

    return {
      count: 0,
      date,
    };
  });

  const report = sanitizeData.map((newUser, key) => {
    let add = 0;

    for (let i = 0; i < key; i++) {
      add += sanitizeData[i].count || 0;
    }

    return {
      count: newUser.count + add,
      date: newUser.date,
    };
  });

  const DATA: {
    options: ApexOptions;
    series: ApexOptions['series'];
  } = {
    series: [
      {
        name: 'Subscripciones nuevas',
        type: 'column',
        data: categories.map((category) => {
          const item = sanitizeData.find(
            (newUser) => category === newUser.date,
          );
          if (item) return item?.count || 0;

          return 0;
        }),
      },
      {
        name: 'Subscripciones registradas',
        type: 'line',
        data: categories.map((category) => {
          const item = report.find((re) => category === re.date);

          if (item) return item?.count || 0;

          return 0;
        }),
      },
    ],
    options: {
      colors: [PRIMARY_COLOR, 'rgba(50,50,50,.85)'],
      chart: {
        height: 300,
        type: 'line',
        defaultLocale: 'es',
        locales: [es],
      },
      stroke: {
        width: [0, 3],
      },
      markers: {
        size: 0,
      },
      dataLabels: {
        enabled: false,
        formatter: function (val: string) {
          return val.toLocaleString();
        },
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        categories: [DateFilter.TODAY, DateFilter.YESTERDAY].includes(filter)
          ? categories.map((category) => moment(category).format('HH:mm'))
          : categories,
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val: number) {
            return val.toLocaleString();
          },
        },
      },
    },
  };

  return (
    <Card
      elevation={2}
      style={{
        border: '1px solid #BBBBBB',
        padding: '16px',
        borderRadius: '16px',
        boxShadow: '0px 0px 8px 0px #00000014',
      }}
    >
      <CardHeader
        classes={{
          title: classes.title,
        }}
        className={classes.header}
        title="Suscripciones registrados"
      />
      <CardContent
        className={classes.content}
        style={{ height: CARD_HEIGHT_DASHBOARD }}
      >
        {loading ? (
          <Box
            fontSize={32}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Loading customSize={CustomSizes.SMALL} />
          </Box>
        ) : (DATA.series?.length ?? 0) > 0 ? (
          /**<Chart
            options={DATA.options}
            series={DATA.series}
            type="line"
            height={'100%'}
          /> */ <></>
        ) : null}
      </CardContent>
    </Card>
  );
};
