export enum RBACPermissions {
  DASHBOARD_ADMINISTRATOR = 'DASHBOARD_ADMINISTRATOR',
  DASHBOARD_TENANT = 'DASHBOARD_TENANT',
  TENANT_ANALYTICS = 'TENANT_ANALYTICS',
  TENANT_USERS = 'TENANT_USERS',
  USERS = 'USERS',
  TENANTS = 'TENANTS',
  TENANT_MAGAZINES = 'TENANT_MAGAZINES',
  TENANT_DASHBOARD_GOOGLE = 'TENANT_DASHBOARD_GOOGLE',
  TENANT_DASHBOARD_SUBSCRIPTION = 'TENANT_DASHBOARD_SUBSCRIPTION',
  TENANT_DASHBOARD_WALL_CRASHES = 'TENANT_DASHBOARD_WALL_CRASHES',
  TENANT_DASHBOARD_COUPONS = 'TENANT_DASHBOARD_COUPONS',
  TENANT_PLANS = 'TENANT_PLANS',
  SETTING_PLANS = 'SETTING_PLANS',
  SETTING_TENANT = 'SETTING_TENANT',
  SETTING_TENANT_INVITATIONS = 'SETTING_TENANT_INVITATIONS',
  SETTING_GLOBAL_USERS = 'SETTING_GLOBAL_USERS',
  SETTING_INVITATIONS = 'SETTING_INVITATIONS',
  SETTINGS = 'SETTINGS',
  CREATE_PLAN = 'CREATE_PLAN',
}
