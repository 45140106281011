import { User, UserRoles } from '../../shared/types/generated';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';


export const checkUserRole = (
  user: User | undefined | null,
  permission: RBACPermissions,
): boolean => {

  const role = user?.roles_on_users?.find((roles_on_user) => roles_on_user?.role?.name === UserRoles.Administrator)?.role;

  return !!RBACSchema.find(
    (schema) => schema.Role === role?.name && schema.Permissions.includes(permission),
  );
};
