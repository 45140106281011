import React, { useEffect, useState } from 'react';
import {
  useCallAction,
  useEvent,
  useStore,
} from '@cobuildlab/react-simple-state';
import { WinnerNote } from '../dashboard-types';
import { TableColumnType } from '../../../shared/components/table/table-types';
import LinkIcon from '@mui/icons-material/Link';
import { TableDefaultSticky } from '../../../shared/components/table/TableDefaultSticky';
import {
  changeDashboardDateFilterStore,
  winnerNoteEvents,
} from '../dashboard-events';
import { winnerNotesPaginate } from '../dashboard-actions';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

type DashboardWinnerNotesProps = {
  loading?: boolean;
};
export const DashboardWinnerNotes: React.FC<DashboardWinnerNotesProps> = ({
  loading = false,
}) => {
  const { data, total } = useEvent(winnerNoteEvents);
  const [notes, setNotes] = useState<WinnerNote[]>([]);
  const { from, to } = useStore(changeDashboardDateFilterStore);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });

  const [callWinnerNotes, loadingPaginate] = useCallAction(
    winnerNotesPaginate,
    {
      onCompleted: (res) => {
        setNotes(res.data);
      },
    },
  );

  const handleOrder = (orderEvent: 'asc' | 'desc'): void => {
    callWinnerNotes({
      from,
      to,
      page: pagination.page,
      pageSize: pagination.pageSize,
      order: orderEvent,
    });
    setOrder(orderEvent);
  };

  useEffect(() => {
    setNotes(data);
  }, [data, data?.length]);

  const COLUMNS: TableColumnType<WinnerNote>[] = [
    {
      columnName: (
        <Box display="flex" color={'#BBBBBB'} alignItems="center">
          <Typography color={'#BBBBBB'}>NOTAS</Typography>
          {order === 'desc' && (
            <ArrowDownward
              fontSize="small"
              onClick={() => handleOrder('asc')}
            />
          )}
          {order === 'asc' && (
            <ArrowUpward
              fontSize="small"
              color="inherit"
              onClick={() => handleOrder('desc')}
            />
          )}
        </Box>
      ),
      columnValue: null,
      columnComponent: (item: WinnerNote) => (
        <Box
          textAlign="left"
          sx={{ wordWrap: 'break-word', width: { xs: '150px', md: '200px' } }}
        >
          {item.url as string}
        </Box>
      ),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>LINK</Typography>,
      columnValue: null,
      columnComponent: (item: WinnerNote) => (
        <a href={item.url} target="_blank">
          <LinkIcon />
        </a>
      ),
    },
    {
      columnName: <Typography color={'#BBBBBB'}>CANTIDAD</Typography>,
      columnValue: (item: WinnerNote) => item.count,
    },
  ];

  return (
    <TableDefaultSticky
      count={total}
      items={notes}
      columns={COLUMNS}
      page={pagination.page}
      pageSize={pagination.pageSize}
      loading={loading || loadingPaginate}
      messageLoading={'Cargando notas ganadoras...'}
      handleChangePage={(page) => {
        setPagination({
          ...pagination,
          page: page + 1,
        });
        callWinnerNotes({
          from,
          to,
          page: page + 1,
          pageSize: pagination.pageSize,
          order,
        });
      }}
      rowsPerPageOptions={[10, 20, 50, 100]}
      handleChangeRowPerPage={(e) => {
        setPagination({
          ...pagination,
          pageSize: parseInt(e.target.value, 10),
          page: 1,
        });
        callWinnerNotes({
          from,
          to,
          page: 1,
          pageSize: parseInt(e.target.value, 10),
          order,
        });
      }}
    />
  );
};
