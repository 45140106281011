import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-hook';
import { Loading } from '../../shared/components/Loading';
import { useDefaultRedirect } from './session-hooks';

type SessionProps = {
  children?: React.ReactNode;
};

export const Session: React.FC<SessionProps> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('auth', { state: { redirectTo: window.location.href } });
    }
  }, [isAuthenticated, navigate]);

  if (loading || !isAuthenticated) return <Loading />;

  return <div>{children}</div>;
};
