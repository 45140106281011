import React from 'react';
import { Route, Routes as RoutesComponent } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { Layout } from './shared/components/layout/Layout';
import { Session } from './modules/session/Session';
import { useAuth } from './modules/auth/auth-hook';
import { Loading } from './shared/components/Loading';
import { Logout } from './modules/auth/Logout';
import { DashboardTenant } from './modules/dashboard/DashboardTenant';
import { CustomSizes } from './shared/components/loading-types';
import { TenantSubsPlanViewLayout } from './modules/tenant-subscription-plans/TenantSubsPlanView';
import { Users } from './modules/user/Users';

export const Routes: React.FC = () => {
  const { loading } = useAuth();

  return loading ? (
    <Loading customSize={CustomSizes.lARGE} />
  ) : (
    <RoutesComponent>
      <Route path="/auth" element={<Auth />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="*"
        element={
          <Session>
            <Layout>
              <RoutesComponent>
                <Route path="/" element={<DashboardTenant />} />
                <Route path="/plans" element={<TenantSubsPlanViewLayout />} />
                <Route path="/users" element={<Users />} />
              </RoutesComponent>
            </Layout>
          </Session>
        }
      />
    </RoutesComponent>
  );
};
